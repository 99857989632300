const CREATE_NOTE_REQUEST = "CREATE_NOTE_REQUEST";
const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";

const VIEW_NOTE_REQUEST = "VIEW_NOTE_REQUEST";
const VIEW_NOTE_SUCCESS = "VIEW_NOTE_SUCCESS";
const VIEW_NOTE_FAILED = "VIEW_NOTE_FAILED";

const DELETE_NOTE_REQUEST = "DELETE_NOTE_REQUEST";
const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
const DELETE_NOTE_FAILED = "DELETE_NOTE_FAILED";

export {
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILED,
  VIEW_NOTE_REQUEST,
  VIEW_NOTE_SUCCESS,
  VIEW_NOTE_FAILED,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILED
}
