const EMAIL_SENDED = "EMAIL_SENDED";
const USER_NOT_FOUND = "USER_NOT_FOUND";
const FORGOT_REQUEST= "FORGOT_REQUEST";
const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
const PASSWORD_CHANGED = "PASSWORD_CHANGED";
const INVALID_CODE = "INVALID_CODE";
export {
  EMAIL_SENDED,
  USER_NOT_FOUND,
  FORGOT_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  PASSWORD_CHANGED,
  INVALID_CODE
}
